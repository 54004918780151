import { useStaticQuery, graphql } from "gatsby";
export const useAcademyOfScienceHeaderSection = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: {
          templateName: { eq: "Page The New York Academy Of Science" }
        }
      ) {
        theNewYorkAcademyOfScience {
          aboutNyasHeadingSection {
            heading
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: WEBP)
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.theNewYorkAcademyOfScience.aboutNyasHeadingSection;
};
