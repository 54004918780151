/* eslint-disable react/no-danger */
import React from "react";
import Spacer from "../../Spacer/Spacer";
import { useAcademyOfScienceMiddleContent } from "../../../hooks/useAcademyOfScience/useAcademyOfScienceMiddleContent";
import "./RightOrganizationSection.scss";

const RightOrganizationSection = () => {
  const { heading, copy, showSection } = useAcademyOfScienceMiddleContent();
  if (showSection)
    return (
      <div className="rightOrganizationSection">
        <div className="container">
          {heading && (
            <>
              <Spacer mobileSize={20} size={30} />
              <h2>{heading}</h2>
            </>
          )}
          <Spacer mobileSize={10} size={20} />
          {copy && (
            <div className="row">
              <div className="col-md-2" />
              <div
                className="col-md-8 content-wrapper"
                dangerouslySetInnerHTML={{
                  __html: copy,
                }}
              />
              <div className="col-md-2" />
            </div>
          )}
          <Spacer mobileSize={50} size={100} />
        </div>
      </div>
    );
  return null;
};

export default RightOrganizationSection;
