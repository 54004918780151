import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import AboutNYASHeaderSection from "../components/TheNewYorkAcadamyOfScience/AboutNYASHeaderSection/AboutNYASHeaderSection";
import RightOrganizationSection from "../components/TheNewYorkAcadamyOfScience/RightOrganizationSection/RightOrganizationSection";
import PromoSlotsSection from "../components/PromoSlotsSection/PromoSlotsSection";
import Footer from "../components/Footer/Footer";
import { useAcademyOfSciencePromoSlots } from "../hooks/useAcademyOfScience/useAcademyOfSciencePromoSlots";

const TheNewYorkAcadamyOfScience = () => {
  const promoSlotsData = useAcademyOfSciencePromoSlots();
  return (
    <Layout>
      <Header />
      <AboutNYASHeaderSection />
      <RightOrganizationSection />
      <PromoSlotsSection data={promoSlotsData} />
      <Footer />
    </Layout>
  );
};

export default TheNewYorkAcadamyOfScience;
