import { useStaticQuery, graphql } from "gatsby";

export const useAcademyOfScienceTopContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: {
          templateName: { eq: "Page The New York Academy Of Science" }
        }
      ) {
        theNewYorkAcademyOfScience {
          aboutNyasTopContent {
            copy
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.theNewYorkAcademyOfScience.aboutNyasTopContent;
};
