import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Spacer from "../../Spacer/Spacer";
import { useAcademyOfScienceHeaderSection } from "../../../hooks/useAcademyOfScience/useAcademyOfScienceHeaderSection";
import { useAcademyOfScienceTopContent } from "../../../hooks/useAcademyOfScience/useAcademyOfScienceTopContent";
import "./AboutNYASHeaderSection.scss";

const AboutNYASHeaderSection = () => {
  const { heading, logo } = useAcademyOfScienceHeaderSection();
  const { copy, showSection } = useAcademyOfScienceTopContent();
  return (
    <div className={`aboutNYASHeaderSection ${logo ? "with-logo" : "no-logo"}`}>
      <div className="aboutNYWASHeaderContainer">
        {heading && <h1 className="h2">{heading}</h1>}
        <Link to="/">
          {logo && (
            <GatsbyImage
              image={logo?.localFile?.childImageSharp?.gatsbyImageData}
              alt={logo?.altText}
              className="aboutNYAS-logo"
            />
          )}
        </Link>
      </div>
      {showSection && copy && (
        <div className="container">
          <Spacer mobileSize={20} size={50} />
          <div className="row">
            <div className="col-md-2" />
            <div
              className="col-md-8 content-wrapper"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: copy,
              }}
            />
            <div className="col-md-2" />
          </div>

          <Spacer mobileSize={20} size={30} />
          <hr size="3" className="divider grey" />
        </div>
      )}
    </div>
  );
};

export default AboutNYASHeaderSection;
