import { useStaticQuery, graphql } from "gatsby";

export const useAcademyOfScienceMiddleContent = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(
        template: {
          templateName: { eq: "Page The New York Academy Of Science" }
        }
      ) {
        theNewYorkAcademyOfScience {
          aboutNyasMiddleContent {
            copy
            heading
            showSection
          }
        }
      }
    }
  `);
  return data.wpPage.theNewYorkAcademyOfScience.aboutNyasMiddleContent;
};
